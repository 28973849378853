import React from 'react';

const NotFoundPage = () => {
  return (
    <>
    <h1>Page Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </>
  );
};

export default NotFoundPage
